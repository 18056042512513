import MDRouter from "@/service/router";
import { mapState, useStore } from "vuex";
import topay from "@/components/order_detail/topay";
import { env } from "md-base-tools/env";
import orderRemark from "@/components/order_detail/orderRemark";
import orderProjectRefund from "@/components/order_detail/orderProjectRefund";
import openboxlist from "@/components/order_detail/openboxlist";
import orderTel from "@/components/order_detail/orderTel";
import DialogBtn from "@/components/order_detail/dialogBtn";
import detailsHeader from "@@/order_detail/detailsHeader.vue";
import detailsContent from "@@/order_detail/detailsContent.vue";
import detailsStatus from "@@/order_detail/detailsStatus.vue";
import detailsAddress from "@@/order_detail/detailsHeaderAddress.vue";
import lowerdev from "@/service/lowerdev";
import { useRoute } from "vue-router";
import orderRefund from "@/components/order_detail/orderRefund";
import ClipboardJS from "clipboard";
// eslint-disable-next-line no-unused-vars
import { postOrder, get, post } from "@/utils/apiBase.js";
import domain from "@/service/domain";
import { showConfirmDialog, showDialog, showSuccessToast, showToast } from "vant";
import orderAppeal from "@/components/order_detail/orderAppeal";
import { getOrderDetail, getPayMethodList } from "@api/index";
import paypay from '@/utils/paypay.js';
export default {
  data() {
    return {
      env: env,
      show: true,
      isMobile: env.isMobile(),
      copyboard: null,
      appealShow: false,
      openCardlistMod: false,
      dialog_show: false,
      remarkShow: false,
      payShow: false,
      orderRefundShow: false,
      miniProgram: false,
      giftWrapShow: false,
      openwxmina: false,
      telShow: false,
      openrefundShow: false,
      orderProjectRefundShow: false,
      dialog_address: false,
      openboxmoreMod: false,
      refundInfo: null,
      html: "",
      refundMsg: "",
      dialog_btn: [],
      addressBtn: [{
        name: "去地址簿选地址",
        title: "去地址簿选地址",
        type: 1
      }, {
        name: "编辑当前订单地址",
        title: "编辑当前订单地址",
        type: 0
      }],
      payPay: null,
      pay_resource: '',
      // 支付方式
      methodsList: [] // 支付方式数组
    };
  },
  components: {
    detailsStatus,
    orderAppeal,
    detailsHeader,
    detailsContent,
    DialogBtn,
    orderTel,
    orderRemark,
    topay,
    orderRefund,
    detailsAddress,
    openboxlist,
    orderProjectRefund
  },
  async setup() {
    const route = useRoute();
    let orderId = route.query.order_id || "";
    const style = route.query.style || "";
    const store = useStore();
    const res = await getOrderDetail({
      id: orderId,
      type: style ? "pay_id" : "order_id",
      pro_id: ""
    });
    if (res.status !== 0) {
      return showToast({
        type: "text",
        message: res.message,
        onClose: () => {
          window.history.back();
        }
      });
    }
    const orderInfo = res.data;
    // orderInfo.aftersale_info = {
    //   "aftersale_id": 0,
    //   "aftersale_type": 0,
    //   "title": "售后中",
    //   "url": "",
    //   "status": 1
    // }
    orderId = orderInfo.order_id;
    store.commit("setValue", {
      obj: "info",
      value: orderInfo
    });
    return {
      orderId,
      orderInfo
    };
  },
  watch: {
    $route() {}
  },
  beforeMount() {
    // 初始化支付工具
    this.payPay = new paypay({
      success: this.PaySuccessBack,
      type: 'pro'
    });
  },
  mounted() {
    if (!this.if_no_header) {
      document.querySelectorAll(".details-box")[0].setAttribute("class", "details-box active viewBox");
    }
    document.body.style.background = "#f2f2f2";
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
    var target = this.$refs.copy;
    this.copyboard = new ClipboardJS(target, {
      text(target) {
        return target.getAttribute("copyData");
      }
    });
    // eslint-disable-next-line no-undef
    userHeader.backnavOnShow(false);
    this.copyboard.on("success", () => {
      // eslint-disable-next-line
      showSuccessToast("复制成功");
    });
    const that = this;
    document.addEventListener("visibilitychange", function () {
      // 用户息屏、或者切到后台运行 （离开页面）
      // 用户打开或回到页面
      if (document.visibilityState === "visible") {
        if (that.openwxmina) {
          location.reload();
        }
      }
    });
  },
  beforeUnmount() {
    document.body.style.background = "#f8f8f8";
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    if_show() {
      return !(this.env.isWeibo() || this.env.isWeixin() || this.env.isModianIos() || this.env.isModianAndroid());
    },
    if_no_header() {
      return !(this.isMobile || this.env.isModianIos() || this.env.isModianAndroid()) || this.env.isWeibo();
    }
  },
  methods: {
    toAftersaleDetails() {
      const url = `/after_sale/detail?order_id=${this.orderId}&full_screen=true`;
      MDRouter.Link(url, 'wap');
    },
    goNext() {
      var searchParams = {
        url: "/apis/mdorder/refund/create",
        method: "post",
        props: {
          order_id: this.orderId,
          refund_reason: this.refundMsg
        }
      };
      postOrder(searchParams.url, searchParams.props).then(resa => {
        if (resa) {
          window.location.reload();
          showToast("操作成功");
        }
      });
    },
    openrefund() {
      this.openrefundShow = true;
    },
    openboxmore() {
      this.openboxmoreMod = true;
    },
    close() {
      showToast({
        type: "text",
        message: "操作成功",
        onClose: () => {
          window.location.reload();
        }
      });
    },
    lookTeamfund() {
      location.href = domain.wap + "/teamfund/" + this.orderInfo.teamfund_id + ".html";
    },
    op(type) {
      const that = this;
      // eslint-disable-next-line eqeqeq
      const orderId = this.orderId;
      // eslint-disable-next-line no-case-declarations
      switch (type) {
        case "subscribe_records":
          // 查看记录
          // eslint-disable-next-line eqeqeq
          location.href = `${domain.wap}/user_order/subscribe_record/${this.orderInfo.product_info.id}`;
          break;
        case "del_order":
          // 确认删除
          showConfirmDialog({
            message: "是否确认要删除？"
          }).then(() => {
            var searchParams = {
              url: "/apis/mdorder/order_del",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast({
                  type: "text",
                  message: "删除成功",
                  onClose: () => {
                    if (history.length > 1) {
                      this.$router.back(-1);
                    } else {
                      lowerdev.openUrl();
                    }
                  }
                });
              }
            });
          });
          break;
        case "cancel_order":
          // 确认取消订单
          showConfirmDialog({
            message: "是否确认要取消？"
          }).then(() => {
            var searchParams = {
              url: "/apis/mdorder/order_cancel",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast({
                  type: "text",
                  message: "取消成功",
                  onClose: () => {
                    window.location.reload();
                  }
                });
              }
            });
          });
          break;
        case "update_address":
          // 确认修改地址
          // this.dialog_address = true
          this.ifAddress();
          break;
        case "update_remark":
          // 确认修改备注
          // lowerdev.confirm({ msg: '是否确认要修改备注？' }, () => {
          //   window.location.href = `${domain.wap}/user_order/update_order_des/${orderId}`
          // })
          this.remarkShow = true;
          break;
        case "look_teamfund":
          location.href = domain.wap + "/teamfund/" + this.orderInfo.teamfund_id + ".html";
          break;
        case "buy_again":
          // webview  点击后跳转至商品详情页
          if (this.orderInfo.extract_card_type && this.orderInfo.extract_card_type > 0) {
            MDRouter.goLuckycardsInfo(this.orderInfo.extract_card_id);
            return false;
          }
          if (this.miniProgram) {
            MDRouter.MinaRouter('ProInfo', {
              pro_id: this.orderInfo.product_info.id
            });
          } else {
            MDRouter.goProInfo(this.orderInfo.product_info.id);
          }
          break;
        case "update_mobile":
          // 确认修改手机号  --> 需要先获取收货地址
          this.telShow = true;
          break;
        case "create_tail":
          if (this.orderInfo.shop_info.skus && this.orderInfo.shop_info.skus[0].sku_id) {
            const arr = this.orderInfo.shop_info.skus.map(e => {
              return String(e.sku_id);
            });
            const obj = {
              presale_pay_type: 2,
              first_order_id: String(orderId),
              sku_id: arr
            };
            location.href = `${domain.wap}/product/payment?order_final_arg=${JSON.stringify(obj)}`;
          }
          break;
        case "pay":
          // 去支付
          if (this.orderInfo.extract_card_type && this.orderInfo.extract_card_type > 0) {
            const luckycardsData = {
              apply_pay_amount: this.orderInfo.pay_amount,
              order_id: this.orderInfo.order_id
            };
            sessionStorage.setItem("luckycardsData", JSON.stringify(luckycardsData));
            MDRouter.goLuckycardsInfo(this.orderInfo.extract_card_id, {
              luckycardsData: JSON.stringify(luckycardsData)
            });
            return false;
          }
          this.toPay();
          break;
        case "delay":
          // 确认延长收货
          postOrder("/apis/mdorder/order_delay_recv", {
            order_id: orderId
          }).then(res => {
            if (res) {
              showToast("操作成功");
              this.$router.go(0);
            }
          });
          break;
        case "cancel_order_refund":
          // 撤销退款
          showConfirmDialog({
            message: "确定要撤销退款吗？"
          }).then(() => {
            var searchParams = {
              url: "/apis/mdorder/refund/cancelled",
              method: "post",
              props: {
                refund_id: that.orderInfo.refund_id
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast({
                  type: "text",
                  message: that.orderInfo.order_type !== 4 ? res.message : res.sys_msg,
                  onClose: () => {}
                });
                this.$router.go(0);
              }
            });
          });
          break;
        case "refund_appeal":
          // 众筹申诉
          this.appealShow = true;
          break;
        case "confirm":
          // 确认-确认收货
          showConfirmDialog({
            message: "请您确认已收到全部商品后再点击“确认收货”，一旦确认将无法撤回。"
          }).then(() => {
            var searchParams = {
              url: "/apis/mdorder/order_recv",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast({
                  type: "text",
                  message: that.orderInfo.order_type !== 4 ? res.message : res.sys_msg,
                  onClose: () => {
                    that.giftWrapShow = true;
                  }
                });
              }
            });
          });
          break;
        case "order_comment_add":
          // 发表评价
          // eslint-disable-next-line eqeqeq
          location.href = `${domain.wap}/product/evaluate/initiate?state=1&order_id=${orderId}&product_id=${this.orderInfo.product_info.id}&rew_id=${this.orderInfo.trade_data.reward_id}`;
          break;
        case "order_comment_edit":
          // 商城订单专属  -->  修改评论
          // 编辑评价
          window.location.href = `${domain.wap}/order_rate/update_rate?back_id=${orderId}&type=shop`;
          break;
        case "order_comment_detail":
          // 查看评论
          location.href = `${domain.wap}/product/evaluate?state=1&order_id=${orderId}&product_id=${this.orderInfo.product_info.id}&rew_id=${this.orderInfo.trade_data.reward_id}`;
          break;
        case "refund_detail":
          window.location.href = `${domain.wap}/user_order/refund_detail/${orderId}`;
          break;
        case "apply_refund":
          // 申请退款 webview
          this.orderRefundShow = true;
          break;
        case "apply_update_address":
          // 申请修改地址
          get("/apis/mdorder/check_address_lock", {
            type: "order_id",
            id: this.orderId
          }).then(e => {
            if (e.data.address_status_lock) {
              showDialog({
                message: "您的申请商家审核中"
              }).then(() => {
                // on close
              });
            } else {
              showConfirmDialog({
                title: "温馨提示",
                message: "您购买的回报在不同地区收取的邮费不同,建议您提前与发起方沟通协商邮费相关事项,以免申请被发起人拒绝",
                confirmButtonText: "申请改地址"
              }).then(() => {
                this.ifAddress();
                // on confirm
              }).catch(() => {
                // on cancel
              });
            }
          });
          break;
        case "cancel_refund":
          // 撤销退款
          showConfirmDialog({
            message: "确定要撤销本次退款申请吗？"
          }).then(() => {
            var searchParams = {
              url: "/mall/order/refund/cancel",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast("操作成功");
                this.$router.go(0);
              }
            });
          });
          break;
        case "update_refund":
          // 更新退款 webview
          // 跳转退款页面
          lowerdev.openUrl("refund", {
            rid: this.orderInfo.refund_id
          });
          break;
        case "apply_deliver":
          //抽卡机发货按钮
          window.location.href = `${domain.wap}/luckycards/container`;
          break;
        case "look_card_goods":
          //抽卡机查看发货单
          window.location.href = `${domain.wap}/luckycards/container_order_list`;
          break;
        default:
          return false;
      }
    },
    ifAddress() {
      get("/apis/address/my_address_count", {
        user_id: this.userId
      }).then(({
        data
      }) => {
        if (data > 1) {
          this.dialog_address = true;
        } else {
          this.$router.push(`/address/add_address?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=project`);
        }
      });
    },
    toAddress(e) {
      if (e === 0) {
        this.$router.push(`/address/add_address?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=project`);
      } else {
        this.$router.push(`/address/address_list?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=project`);
      }
    },
    refundFn(e) {
      const orderId = this.orderId;
      showConfirmDialog({
        message: "确定要申请退款吗？"
      }).then(() => {
        get("/apis/mdorder/related_reward", {
          order_id: orderId
        }).then(res => {
          if (res.data && res.data.list && res.data.list.length) {
            this.refundMsg = e;
            this.refundInfo = res.data;
            this.orderProjectRefundShow = true;
          } else {
            var searchParams = {
              url: "/apis/mdorder/refund/create",
              method: "post",
              props: {
                order_id: orderId,
                refund_reason: e
              }
            };
            postOrder(searchParams.url, searchParams.props).then(resa => {
              if (resa) {
                window.location.reload();
                showToast("操作成功");
              }
            });
          }
        });
      });
    },
    callWeixinPay(json) {
      const loadStatusData = setInterval(() => {
        // eslint-disable-next-line no-undef
        if (WeixinJSBridge !== undefined) {
          clearInterval(loadStatusData);
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke("getBrandWCPayRequest", json, res => {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.log(res.err_msg);
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.PaySuccessBack();
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              showToast("取消支付");
            } else {
              showToast(res.err_msg);
            }
          });
        }
      }, 300);
    },
    PaySuccessBack() {
      window.location.href = `${domain.wap}/product/payment/success?pay_id=${this.pay_id}`;
    },
    async toPay() {
      let option = {
        type: 3,
        // 支付类型 1、众筹项目id 2、电商spuid 3、订单id
        id: this.orderId // 统一数据id，根据类型判断，有多个逗号分隔
      };
      const {
        status,
        data,
        message
      } = await getPayMethodList(option);
      if (status == 0) {
        if (data) {
          this.methodsList = data;
          const payMethod = data.find(obj => obj.is_default === 1) || data[0];
          this.pay_resource = payMethod.pay_type;
          // 仅有一种支付方式则直接调起支付
          if (data.length == 1) {
            this.payClick(payMethod.pay_type);
          } else {
            this.payShow = true;
          }
        } else {
          showToast('此订单不支持当前支付方式');
        }
      } else {
        showToast(message);
      }
    },
    payClick(pay_resource) {
      sessionStorage.setItem('FailNext', 'reload');
      let obj = {
        pay_id: this.orderInfo.pay_id || '',
        user_id: this.user_id,
        pay_resource: pay_resource
      };
      this.payPay.toPay({
        params: obj,
        order_id: this.orderId
      });
    },
    show_dialog(e) {
      this.dialog_btn = JSON.parse(JSON.stringify(e));
      this.dialog_show = true;
    },
    close_dialog() {
      this.dialog_show = false;
    },
    copy() {
      var target = this.$refs.copy;
      this.copyboard = new ClipboardJS(target, {
        text(target) {
          return target.getAttribute("copyData");
        }
      });
      this.copyboard.on("success", () => {
        // eslint-disable-next-line
        showSuccessToast("复制成功");
      });
    },
    dropBack() {
      if (history.length > 1) {
        this.$router.back(-1);
      } else {
        lowerdev.openUrl();
      }
    }
  }
};