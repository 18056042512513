import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createBlock(_component_van_popup, {
    class: _normalizeClass(['popup-box', `popup-box-${$props.type}`]),
    show: $options.show_pop,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => $options.show_pop = $event),
    onOpen: $options.open,
    round: "",
    position: "bottom",
    teleport: "body"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }, 8, ["class", "show", "onOpen"]);
}