import CompPopupBottom from "@@/compose/popupBottom.vue";
export default {
  props: ["show", "info"],
  components: {
    CompPopupBottom
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    goOrderDetails(orderId) {
      this.$router.push(`/order/project_order_detail?order_id=${orderId}`);
    },
    goNext() {
      this.$emit("goNext");
    },
    closePopup() {
      this.$emit("closePopup");
    }
  }
};