// import lowerdev from '@/service/lowerdev'
import { post } from "@/utils/apiBase.js";
import { showToast } from "vant";
import { mapState } from "vuex";
export default {
  name: "orderRemark",
  data() {
    return {
      mes: ""
    };
  },
  components: {},
  watch: {},
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  props: ["orderType", "orderInfo", "orderId", "remark"],
  mounted() {
    this.$nextTick(() => {
      console.log(this.orderInfo);
    });
  },
  methods: {
    sub() {
      const order = {
        url: "/apis/mdorder/refund/appeal_apply",
        method: "post",
        props: {
          refund_id: this.orderInfo.refund_id,
          appeal_content: this.mes
        }
      };
      post(order.url, order.props).then(res => {
        showToast({
          type: "text",
          message: res.message,
          onClose: () => {}
        });
        this.$emit("ok");
      });
    },
    dialogBack() {
      this.$emit("dialogBack");
    },
    op(type) {
      this.$emit("toOp", type);
    }
  }
};