export default {
  props: ["show", "type"],
  emits: ['open'],
  computed: {
    show_pop: {
      get() {
        return this.show;
      },
      set() {}
    }
  },
  methods: {
    open() {
      this.$emit('open');
    }
  }
};