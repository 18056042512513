import MDRouter from "@/service/router";
// import lowerdev from '@/service/lowerdev'
import { get } from "@/utils/apiBase.js";
import { mapState } from "vuex";
export default {
  name: "openboxlist",
  data() {
    return {
      mes: "",
      list: []
    };
  },
  components: {},
  watch: {},
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  props: ["orderId", "orderInfo"],
  mounted() {
    this.getlist();
  },
  methods: {
    toProject() {
      MDRouter.goProInfo(this.orderInfo.product_info.id);
    },
    close() {
      this.$emit("close");
    },
    getlist() {
      // console.log(this.orderInfo)
      const order = {
        url: "/apis/mdorder/order_packs",
        method: "get",
        props: {
          order_id: this.orderId
        }
      };
      get(order.url, order.props).then(e => {
        if (e.status == 0) {
          this.list = e.data.list;
        }
      });
    },
    dialogBack() {
      this.$emit("dialogBack");
    },
    op(type) {
      this.$emit("toOp", type);
    }
  }
};